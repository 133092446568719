<template lang="pug">
  v-dialog(
    v-model="openDialog"
    eager
    width="500"
  )
    v-card
      v-toolbar(
        color="yellow darken-3"
        dark
        dense
        flat
      )
        span Edit Detail
      v-container
        v-row
          v-col(cols="6")
            v-text-field(
              label="Balance"
              readonly
              :value="balance.phpFormat()"
            )
          v-col(cols="6")
            v-autocomplete(
              label="Account Code"
              :loading="!accountCodes.length"
              v-model="accountCodeId"
              :items="accountCodes"
              item-value="account_code.id"
              item-text="account_code.account_code"
            )
              template(v-slot:selection="{ item }")
                span.primary--text.mr-2 {{ item.account_code.account_code }}
                small.blue-grey--text {{ item.account_code.description }}
              template(v-slot:item="{ item }")
                span.primary--text.mr-2 {{ item.account_code.account_code }}
                small.blue-grey--text {{ item.account_code.description }}
          v-col(cols="6")
            v-text-field(
              label="Amount"
              v-model="amount"
            )
          v-col(cols="6")
            v-text-field(
              label="Description"
              v-model="description"
            )
          v-col(cols="3")
            v-btn(
              dark
              small
              color="yellow darken-3"
              dense
              block
              :loading="ptCashVoucherDetailUpdatePutting"
              @click="update"
            )
              span Update
          v-col(cols="3")
            v-btn(
              dark
              small
              block
              color="error"
              dense
              @click="openDialog = false"
            )
              span Cancel
</template>
<script>
import ptCashVoucherDetailRepository from '@/repositories/pt-cash-voucher-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [ptCashVoucherDetailUpdateVars, ptCashVoucherDetailUpdateVarNames] = requestVars({ identifier: 'pt-cash-voucher-detail-update', request: 'put', hasData: false })

const ptCashVoucherDetailUpdateHandler = new VueRequestHandler(null, ptCashVoucherDetailUpdateVarNames)

const inputVars = () => ({
  id: null,
  accountCodeId: null,
  description: null,
  amount: null,
})

export default {
  name: 'EditPtCashvoucherDetail',
  props: ['value', 'edit', 'accountCodes'],
  data () {
    return {
      ...ptCashVoucherDetailUpdateVars,
      ...inputVars(),
    }
  },
  computed: {
    accountCode () {
      return this.accountCodes.find(item => item.account_code.id === this.accountCodeId)
    },
    balance () {
      return this.amountRemaining - this.amount
    },
    amountRemaining () {
      if (!this.accountCode) return 0
      if (this.edit.account_code_id === this.accountCode.account_code.id) {
        return this.accountCode.balance + this.edit.amount
      }
      return this.accountCode.balance
    },
    voucherId () {
      return this.$route.params.voucherId
    },
    openDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    edit () {
      this.populateInputs()
    },
  },
  methods: {
    populateInputs () {
      Object.keys(inputVars())
        .forEach(key => {
          this[key] = this.edit[key.camelToSnakeCase()]
        })
    },
    update () {
      const params = {
        title: 'Update Detail?',
        icon: 'mdi-alert',
        color: 'yellow darken-3',
      }
      this.$confirm('Are you sure you want to update?', params)
        .then(confirm => {
          if (!confirm) return
          const handler = ptCashVoucherDetailUpdateHandler
          const repository = ptCashVoucherDetailRepository.update
          const { id, ...data } = this.getInputData()
          handler.setVue(this)
          handler.execute(repository, [id, data], this.reset)
        })
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
      this.openDialog = false
    },
    getInputData () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
  },
}
</script>
